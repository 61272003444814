body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100%;
}

.layout {
  max-width: 575px;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 0 auto;
}

@supports (-webkit-backdrop-filter: none) {
  .element-blur {
    -webkit-backdrop-filter: blur(2px);
  }
}

/* reset CSS */
header,
footer,
section,
article,
aside,
nav,
hgroup,
details,
menu,
figure,
figcaption {
  display: block;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
div,
li,
dl,
dt,
dd,
form,
iframe,
p,
a,
span,
blockquote,
i,
figure,
fieldset,
img,
table,
th,
td,
input,
textarea,
select,
caption,
button,
pre,
small {
  margin: 0;
  padding: 0;
}

html,
body {
  min-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
}

ol,
ul {
  list-style: none;
}

fieldset {
  border: 0 solid transparent;
}

img {
  border: none;
  line-height: 0;
  vertical-align: top;
}

table {
  border-collapse: collapse;
}

table,
th,
td {
  border-spacing: 0;
}

input,
textarea,
select {
  border: 0;
  border-radius: 0;
  vertical-align: middle;
  outline: none;
}

caption {
  visibility: hidden;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}

button {
  appearance: none;
  border: 0;
  background: none;
  font-size: inherit;
  cursor: pointer;
  line-height: 1.5;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

em {
  font-style: normal;
}

a {
  color: black;
  text-decoration: none;
}
